let config = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "forwood-riotinto-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-riotinto-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-east-1",
        URL: "https://jhdms2uj6ngzhk7d6y5brmtrje.appsync-api.us-east-1.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://h9yy3fnbp1.execute-api.us-east-1.amazonaws.com/env",
        USER_TOKEN_URL: "https://de4ushaec8.execute-api.us-east-1.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.riotinto.forwoodsafety.com",
        WEBSOCKET: "wss://w0ii35o49j.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_gbRwzUwDX",
        IDENTITY_POOL_ID: "us-east-1:cca62aad-f400-4e99-b988-2e938d26b6ca",
        USERPOOL_HOSTED_DOMAIN: "forwood-rio-id",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.riotinto.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.riotinto.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::503504336352:role/riotinto-CA"
    },
    samlProvider: {
      NAME: 'Riotinto',
      SUPPORTED_LIST: 'Riotinto,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.5",
        HOSTNAME: "id.riotinto.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".riotinto.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "ad693bbf-7a7f-4a1c-a426-675a6eaffea7",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.riotinto.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
